import React from "react";
import { Helmet } from "react-helmet";
import { asHTML } from "@prismicio/helpers";

import { linkResolver } from "../../utils/prismic-linkResolver";

const FaqSchema = ({ body }) => {
  const faqItems = body
    .map((slice) => {
      if (slice.slice_type === "accordion") return slice.items;
      return null;
    })
    .flat()
    .filter((item) => item?.acc_header)
    .map(
      (item) => `{
        "@type": "Question",
        "name": "${item.acc_header}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${asHTML(item.acc_content.richText, linkResolver).replace(/"/g, "&quot;")}"
        }
      }`
    );

  if (faqItems.length < 1) return null;

  return (
    <Helmet>
      <script type="application/ld+json">{`{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [${faqItems.join(",")}]
      }`}</script>
    </Helmet>
  );
};

export default FaqSchema;

import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";

import { Col, Container, Row } from "react-bootstrap";

import AnchorPrismic from "../elements/AnchorPrismic";
import ScrollToTarget from "../elements/ScrollToTarget";

import "../../styles/components/blocks/header-background.scss";

const HeaderBackground = ({
  title,
  description,
  image,
  imageMobile,
  headerSize = 1,
}) => {
  return (
    <header className="header-background">
      <figure className="header-background__image horizontal-shadow horizontal-shadow--bottom">
        {image.gatsbyImageData && (
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.alt || title || ""}
            className={
              imageMobile?.gatsbyImageData ? "d-none d-md-block" : null
            }
            loading="eager"
          />
        )}
        {imageMobile?.gatsbyImageData && (
          <GatsbyImage
            image={imageMobile.gatsbyImageData}
            alt={imageMobile.alt || title || ""}
            className="d-md-none"
            loading="eager"
          />
        )}
      </figure>
      <div className="header-background__container">
        <Container fluid>
          <Row>
            <Col
              xl={{ span: 10, offset: 1 }}
              className="col-xxxl-6 offset-xxxl-3"
            >
              <div className="header-background__content p-3 p-sm-5 pb-5 pb-sm-7">
                {title && (
                  <h1
                    className={`h${headerSize} mb-4 mb-sm-5`}
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                )}
                {description && description.text !== "" && (
                  <RichText
                    render={description.richText}
                    serializeHyperlink={AnchorPrismic}
                  />
                )}
                <ScrollToTarget />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  );
};

export default HeaderBackground;

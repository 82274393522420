import React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import { Col, Container, Row } from "react-bootstrap";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import HeaderBackground from "../components/blocks/HeaderBackground";
import AnchorPrismic from "../components/elements/AnchorPrismic";
import SliceZone from "../components/common/SliceZone";
import { constructActiveDoc } from "../helpers";
import { InfoBubble } from "../helpers/Icons";

import "../styles/pages/support.scss";
import FaqSchema from "../components/meta/FaqSchema";

const Support = ({ data }) => {
  if (!data) return null;

  const { settings, page } = data;
  const {
    title,
    description,
    image,
    image_mobile: imageMobile,
    body,
    accordion_main_header: accordionMainHeader,
    informations,
  } = page.data;

  return (
    <>
      <Seo page={page} settings={settings} />
      <FaqSchema body={body} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <article className="support-page">
          <HeaderBackground
            title={title}
            description={description}
            image={image}
            imageMobile={imageMobile}
            headerSize="2"
          />
          <Container className="mw-1920" fluid>
            <Row>
              {informations.text && (
                <Col xs={12} lg={6} className="col-xxxl-4 offset-xxxl-1">
                  <section className="support-page__info">
                    <InfoBubble />
                    <RichText
                      render={informations.richText}
                      serializeHyperlink={AnchorPrismic}
                    />
                  </section>
                </Col>
              )}
              <Col xs={12} lg={6} className="col-xxxl-6">
                {accordionMainHeader && (
                  <h2
                    dangerouslySetInnerHTML={{ __html: accordionMainHeader }}
                    className="h3 mt-5"
                  />
                )}
                <SliceZone slices={body} settings={settings} />
              </Col>
            </Row>
          </Container>
        </article>
      </Layout>
    </>
  );
};

export const query = graphql`
  query supportQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicSupport(lang: { eq: $lang }) {
      ...prismicSupportFragment
    }
  }
`;

export default withPrismicPreview(Support);
